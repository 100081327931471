export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT'
};

export const USER_ROLES =  {
    ADMIN: 'Administrator',
    USER: 'User'
};

export const USER_ACCOUNT_STATUS =  {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
};

export const USER_ROLES_ENUM =  {
    ADMIN: 'ADMIN',
    USER: 'USER'
};

export const USER_STATUS =  {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    PENDING: 'PENDING'
};